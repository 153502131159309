const menuExpand = [].slice.call(document.querySelectorAll('.menu-expand'))
const backLink = `<li class="menu-item"><a class="menu-link menu-back-link" href="javascript:;">Back</a></li>`

menuExpand.filter(item => !!item).forEach(item => {
	const content = item.querySelector('.menu-expand-content')
	const link = item.querySelector('.menu-link')

	if (content) content.insertAdjacentHTML('afterbegin', backLink)
	const back = item.querySelector('.menu-back-link')
	if (link) link.addEventListener('click', () => item.classList.add('active'))
	if (back) back.addEventListener('click', () => {
		console.log('back 1', item.classList)
		item.classList.remove('active')
		console.log('back 2', item.classList)
	})
})

const ham = document.getElementById('ham')
const btn = document.getElementById('ham-btn')
ham.addEventListener('click', function() {
	btn.classList.toggle('active')
	document.body.classList.toggle('menu-is-toggled')
})
